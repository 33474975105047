<template>
  <div style="min-height: 400px">
    <div style="display: flex">
      <ScreenSelect
        :typeOptions="typeOption"
        placeholder="全部传感器"
        @changeType="changeType"
        :show-date-range="false"
      />
      <ScreenSelect
        :typeOptions="typeOptions"
        placeholder="全部类型"
        @changeType="changeSensor"
        :show-date-range="false"
        :showScreen="false"
      />
    </div>
    <a-table
      :columns="columns"
      :data-source="tableData"
      :pagination="pagination"
      :row-key="record => record.pkId"
      :loading="loading"
      :scroll="{ x: 900 }"
      @change="handlePagination"
    />
  </div>
</template>

<script>
import { Component, Prop, Vue } from 'vue-property-decorator';
import { deviceAlarmList } from '@/services/device-manage/device';
import { deviceTypeEnum } from '@/enum/device';
import ScreenSelect from '@/views/safety-device/component/screen-select';
import {
  deviceTypeList,
  getPitPonint,
} from '@/services/device-manage/device-detail';

@Component({
  components: {
    ScreenSelect,
  },
})
export default class PitWarning extends Vue {
  @Prop({ type: String, default: '' }) pageType;
  @Prop({ type: String, default: '' }) deviceId;

  get columns() {
    return [
      {
        dataIndex: 'index',
        customRender: (text, record, index) => (
          <span>{(this.pagination.current - 1) * 10 + index + 1}</span>
        ),
        title: '序号',
        fixed: 'left',
        align: 'center',
        width: 80,
      },
      {
        align: 'left',
        title: '时间',
        dataIndex: 'alarmTime',
        customRender: txt =>
          txt ? this.dayjs(txt).format('YYYY-MM-DD HH:mm:ss') : '',
      },
      {
        align: 'left',
        title: '传感器',
        dataIndex: 'positionId',
      },
      {
        align: 'left',
        title: '类型',
        dataIndex: 'subType',
        ellipsis: true,
        customRender: txt => (txt ? this.changeSubType(txt) : ''),
      },
      {
        align: 'left',
        title: '报警详情',
        dataIndex: 'reason',
        ellipsis: true,
      },
    ];
  }
  changeSubType(val) {
    const typeList = this.typeOptions;
    const idx = typeList.findIndex(v => v.value === val);
    return typeList[idx].label;
  }
  pagination = {
    current: 1,
    pageSize: 10,
    total: 0,
    showSizeChanger: true,
    pageSizeOptions: ['10', '20', '30', '40'],
    showTotal: total => `${total} ` + '条',
  };
  typeOption = [];
  async getPitPointList() {
    if (!this.deviceId) {
      return;
    }
    const params = {
      deviceId: this.deviceId,
    };
    try {
      const res = await getPitPonint(params, this.deviceGroup);
      this.typeOption = res.map(v => ({
        label: v.text,
        value: v.value,
      }));
    } catch {
      return false;
    }
  }
  selectSensor = {
    type: '',
    value: '',
  };
  changeSensor(v) {
    if (v && v.value !== 'all') {
      this.selectSensor = v;
    } else {
      this.selectSensor = {
        type: '',
        value: '',
      };
    }
    this.pagination.current = 1;
    this.getTableList();
  }
  tableData = [];
  mounted() {
    this.getTableList();
    this.getTypeList();
    this.getPitPointList();
  }
  typeOptions = [];
  async getTypeList() {
    try {
      this.typeOptions = await deviceTypeList(this.deviceGroup);
    } catch {
      return false;
    }
  }
  get deviceGroup() {
    return deviceTypeEnum[this.pageType].deviceGroup;
  }
  rangeTime = {
    startTime: '',
    endTime: '',
  };
  selectTime(t) {
    if (t && t.startTime) {
      this.rangeTime.startTime = t.startTime;
      this.rangeTime.endTime = t.endTime;
    } else {
      this.rangeTime = {
        startTime: '',
        endTime: '',
      };
    }
    this.pagination.current = 1;
    this.getTableList();
  }
  selectType = '';
  changeType(v) {
    if (v && v.value !== 'all') {
      this.selectType = v.value;
    } else {
      this.selectType = '';
    }
    this.pagination.current = 1;
    this.getTableList();
  }
  loading = false;
  async getTableList() {
    const params = {
      deviceId: this.deviceId,
      query: {
        current: this.pagination.current,
        size: this.pagination.pageSize,
      },
      deviceGroup: this.deviceGroup,
    };
    if (this.rangeTime.startTime) {
      params.startTime = this.rangeTime.startTime;
      params.endTime = this.rangeTime.endTime;
    }
    if (this.selectType) {
      params.positionId = this.selectType;
    }
    if (this.selectSensor.value) {
      params.type = this.selectSensor.type;
      params.subType = this.selectSensor.value;
    }
    this.loading = true;
    try {
      const { records, current, size, total } = await deviceAlarmList(
        params,
        this.deviceGroup,
      );
      this.tableData = records;
      this.pagination.current = current;
      this.pagination.pageSize = size;
      this.pagination.total = total;
      this.loading = false;
    } catch {
      this.loading = false;
      return false;
    }
  }
  handlePagination(data) {
    this.pagination = data;
    this.getTableList();
  }
}
</script>

<style></style>
