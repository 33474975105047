<template>
  <div
    class="konva"
    ref="konvaRef"
    :style="{ backgroundImage: `url(${background})` }"
  ></div>
</template>

<script>
import { Component } from 'vue-property-decorator';
import { mixins } from 'vue-class-component';
import PlanViewCanvasMixin from '../../mixin/plan-view-canvas';
import { planViewEnum } from '../../mixin/enum';

@Component
export default class PlanViewCanvas extends mixins(PlanViewCanvasMixin) {
  planViewType = planViewEnum.other;
}
</script>

<style lang="less" scoped>
.konva {
  width: max-content;
  background-size: contain;
  background-position: center;
  background-repeat: no-repeat;
  overflow: hidden;
}
</style>
