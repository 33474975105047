<template>
  <div :class="$style.listWrap">
    <div style="display: flex">
      <ScreenSelect
        :typeOptions="typeOptions"
        placeholder="全部传感器"
        @changeType="changeType"
        :show-date-range="false"
      />
      <ScreenSelect
        :typeOptions="sensorOptions"
        placeholder="全部数据类型"
        @changeType="changeSensor"
        :show-date-range="false"
        :showScreen="false"
      />
    </div>
    <a-table
      :columns="columns"
      :data-source="tableData"
      :pagination="pagination"
      :row-key="record => record.pkId"
      :loading="loading"
      @change="handlePagination"
    >
      <template slot="weight" slot-scope="text, record">
        <div>
          <span style="margin-right: 5px">{{ addUnit(text, record) }}</span>
          <a-icon
            v-if="record.isalarm"
            :class="$style.alarm"
            type="warning"
            theme="filled"
          />
        </div>
      </template>
    </a-table>
  </div>
</template>

<script>
import { Component, Prop, Vue, Watch } from 'vue-property-decorator';
import { deviceTypeEnum } from '@/enum/device';
import {
  deviceRecord,
  getPitPonint,
  pitTypeList,
} from '@/services/device-manage/device-detail';
import ScreenSelect from '@/views/safety-device/component/screen-select';
import moment from 'moment';

@Component({
  components: {
    ScreenSelect,
  },
})
export default class DeviceStatus extends Vue {
  @Prop({ type: String, default: '' }) pageType;
  @Prop({ type: String, default: '' }) deviceId;

  @Watch('deviceId')
  changeDeviceId(newVal, oldVal) {
    if (newVal && newVal !== oldVal) {
      this.deviceId = newVal;
      this.getTableList();
      this.getPitPointList();
      this.getTypeList();
    }
  }
  mounted() {
    this.getTableList();
    this.getTypeList();
    this.getPitPointList();
  }
  sensorOptions = [];
  async getTypeList() {
    try {
      const res = await pitTypeList(this.deviceGroup);
      this.sensorOptions = res.map(v => ({
        label: v.text,
        value: v.value,
        unit: v.unit,
      }));
    } catch {
      return false;
    }
  }
  formateTime(t) {
    return t ? this.dayjs(t).format('YYYY-MM-DD HH:mm:ss') : '';
  }
  get columns() {
    return [
      {
        align: 'left',
        title: this.$t('safety.reportTime'),
        dataIndex: 'createdTime',
        customRender: txt =>
          txt ? moment(txt).format('YYYY-MM-DD HH:mm:ss') : '-',
      },
      {
        align: 'left',
        title: '传感器',
        // width: 100,
        dataIndex: 'secondaryDeviceId',
      },
      {
        align: 'left',
        title: '数据类型',
        dataIndex: 'type',
        // width: 100,
        customRender: txt => (txt ? this.changeSubType(txt) : '') ?? '-',
      },
      {
        align: 'left',
        title: '数据值',
        // width: 100,
        dataIndex: 'value',
        customRender: (txt, record) => (txt ? this.addUnit(txt, record) : '-'),
      },
      {
        align: 'left',
        title: '报警状态',
        // width: 100,
        dataIndex: 'isalarm',
        customRender: text => (text ? '报警' : '正常'),
      },
      // {
      //   align: 'left',
      //   title: this.$t('detail.currentValue'),
      //   dataIndex: 'value',
      //   scopedSlots: { customRender: 'currentValue' },
      //   // customRender: (txt, record) => (txt ? this.addUnit(txt, record) : ''),
      // },
      // {
      //   align: 'left',
      //   title: this.$t('detail.alarmValue'),
      //   dataIndex: 'alarm',
      // },
    ];
  }
  pagination = {
    current: 1,
    pageSize: 10,
    total: 0,
    showSizeChanger: true,
    pageSizeOptions: ['10', '20', '30', '40'],
    showTotal: total => `${total} ${this.$t('common.total')}`,
  };
  changeSubType(val) {
    const typeList = this.sensorOptions;
    const { label } = typeList.find(v => v.value === val);
    return label;
  }
  addUnit(txt, record) {
    const { unit } = this.sensorOptions.find(v => v.value === record.type);
    return txt + unit;
  }
  get deviceGroup() {
    return deviceTypeEnum[this.pageType].deviceGroup;
  }
  loading = false;
  typeOptions = [];
  selectSensor = '';
  changeSensor(v) {
    if (v && v !== 'all') {
      this.selectSensor = v;
    } else {
      this.selectSensor = '';
    }
    this.pagination.current = 1;
    this.getTableList();
  }
  async getPitPointList() {
    if (!this.deviceId) {
      return;
    }
    const params = {
      deviceId: this.deviceId,
    };
    try {
      const res = await getPitPonint(params, this.deviceGroup);
      this.typeOptions = res.map(v => ({
        label: v.text,
        value: v.value,
      }));
    } catch {
      return false;
    }
  }
  selectType = '';
  changeType(v) {
    if (v && v !== 'all') {
      this.selectType = v;
    } else {
      this.selectType = '';
    }
    this.pagination.current = 1;
    this.getTableList();
  }
  tableData = [];
  async getTableList() {
    if (!this.deviceId) {
      return;
    }
    this.tableData = [];
    const params = {
      pkId: this.deviceId,
      current: this.pagination.current,
      size: this.pagination.pageSize,
    };
    if (this.selectType?.value) {
      params.secondaryDeviceId = this.selectType.value;
    }
    if (this.selectSensor?.value) {
      params.type = this.selectSensor.value;
    }
    try {
      this.loading = true;
      const { records, current, size, total } = await deviceRecord(
        params,
        this.deviceGroup,
      );
      this.tableData = records;
      this.pagination.current = current;
      this.pagination.pageSize = size;
      this.pagination.total = total;
      this.loading = false;
    } catch {
      this.loading = false;
      return false;
    }
  }
  handlePagination(data) {
    this.pagination = data;
    this.getTableList();
  }
}
</script>
<style lang="less" module>
.listWrap {
  min-height: 400px;
  .alarm {
    color: var(--delete);
  }
}
</style>
